import {ArrowRight, X, Menu} from 'react-feather';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {graphql} from 'gatsby';
import {Link} from 'gatsby';
import * as Yup from 'yup';
import c8S from 'classnames';
import {Helmet} from 'react-helmet';
import React, {useState, useRef, useLayoutEffect} from 'react';
import './docs.css';

import {Search} from '../components/Search';
import fetch from '../components/fetch';
import navDocs from '../utils/sectionList';
import Toc from '../components/ToC';

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email!')
    .required('Email is required!'),
});

const NavItem = ({activePath, title, id, items}) => {
  const path = '/docs/' + id + '.html';

  return (
    <li className="mt-1 mb-1">
      {id ? (
        <Link to={path} className={c8S({'text-primary': path === activePath})}>
          {title}
        </Link>
      ) : (
        <p className="mt-6 font-medium">{title}</p>
      )}
      {items && <Nav items={items} activePath={activePath} />}
    </li>
  );
};

const Nav = ({items, activePath}) => (
  <ul>
    {items.map((item, i) => (
      <NavItem {...item} activePath={activePath} key={i} />
    ))}
  </ul>
);

export default props => {
  const {data, location} = props;
  const {siteMetadata} = data.site;
  const {
    excerpt,
    frontmatter,
    headings,
    html,
    timeToRead,
  } = data.markdownRemark;

  const title = `${frontmatter.title} - Fleet Serverless function fastest`;

  const docsRef = useRef(null);

  const [successMessage, setSuccessMessage] = useState();
  const [visible, setVisible] = useState();

  useLayoutEffect(() => {
    if (visible) {
      document.body.style = 'position: fixed; width: 100%;';
    } else {
      document.body.style = '';
    }
  }, [visible]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          content="https://fleetfn.com/images/Twitter-card@2x.png"
          name="og:image"
        />
        <meta
          content="https://fleetfn.com/images/Twitter-card@2x.png"
          name="twitter:image"
        />
        <meta content="article" name="og:type" />
        <meta content="Reading time" name="twitter.label1" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={`${timeToRead} min read`} name="twitter:data1" />
        <meta content={excerpt} name="description" />
        <meta content={excerpt} name="og:description" />
        <meta content={excerpt} name="twitter:description" />
        <meta content={siteMetadata.author} name="twitter:creator" />
        <meta content={title} name="og:title" />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.css"
        />
        <script src="https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.js" />
      </Helmet>
      <div className="antialiased flex flex-col md:flex-row">
        <nav
          className={c8S('sidebar pt-4', {
            'pb-1 md:pb-0 border-b md:border-none border-secondary-100': !visible,
          })}
        >
          <div className="flex justify-between items-center pb-2 px-6 md:px-10">
            <div className="flex flex-row items-center">
              <Link to="/">
                <div className="logo">
                  <img src="/images/logo.svg" alt="Fleet" />
                </div>
              </Link>
              <span className="pl-2 text-gray-light text-2xl font-light leading-normal">
                docs
              </span>
            </div>
            <button
              className="md:hidden"
              aria-label="Open menu"
              onClick={() => setVisible(!visible)}
            >
              {visible ? <X /> : <Menu />}
            </button>
          </div>
          <div
            className={c8S(
              'sidebar-nav px-6 md:px-10 fixed md:relative w-full pb-6 md:pt-6 left-0 px-6 md:px-0 z-10 bg-white md:block overflow-y-auto',
              {hidden: !visible}
            )}
          >
            <Nav
              items={navDocs}
              activePath={location.pathname}
              visible={visible}
            />
          </div>
        </nav>
        <div className="docs-main flex flex-col px-6 md:px-10 h-full">
          <div className="flex bg-white sticky h-16 w-full z-10">
            <div className="flex mt-4 w-full">
              <Search />
              <div className="flex items-center justify-end w-2/4">
                <ul className="flex">
                  <li className="mr-4">
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <a
                      className="flex items-center"
                      href="https://console.fleetfn.com"
                    >
                      Sign in <ArrowRight className="ml-2" size={16} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex">
            <div
              className={c8S('pt-10 pb-10', {
                'w-full md:w-4/6': !frontmatter.disableTableOfContents,
                'w-full': frontmatter.disableTableOfContents,
              })}
            >
              <h1 className="text-4xl md:w-2/4 leading-tight font-normal tracking-wider">
                {frontmatter.title}
              </h1>
              <p className="text-gray text-xl mt-4 mb-4">
                {frontmatter.description}
              </p>
              <div
                className="docs"
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
                ref={docsRef}
              />
              <footer className="flex flex-col md:flex-row mt-12 py-6">
                <div className="md:w-3/6 mr-6">
                  <h4 className="text-lg font-normal mt-4">Questions?</h4>
                  <p className="py-2 text-gray">
                    We're always happy to help with code or other questions you
                    might have.{' '}
                    <a href="#algolia-doc-search" className="text-primary">
                      Search our documentation
                    </a>
                    ,{' '}
                    <a href="mailto:hello@fleetfn.com" className="text-primary">
                      contact support
                    </a>
                    , or{' '}
                    <Link to="/contact" className="text-primary">
                      connect with our sales team
                    </Link>
                    .
                  </p>
                </div>
                <div className="md:w-3/6">
                  <h4 className="text-lg font-normal mt-4">
                    Sign up for the Developer Newsletter
                  </h4>
                  <p className="py-2 text-gray">
                    Share your email so Fleet can send you updates about the API
                    and developer platform.
                  </p>
                  <Formik
                    initialValues={{email: ''}}
                    validationSchema={formSchema}
                    onSubmit={(
                      values,
                      {setSubmitting, setErrors, resetForm}
                    ) => {
                      fetch('newsletter', {method: 'PUT', body: values})
                        .then(res => {
                          resetForm();
                          setSuccessMessage(res.message);
                          setSubmitting(false);
                        })
                        .catch(err => {
                          if (err.error) {
                            setErrors({email: err.error});
                          } else {
                            setErrors({
                              email:
                                'Error when making the request, try again.',
                            });
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({isSubmitting}) => (
                      <Form>
                        <div className="flex rounded border border-secondary">
                          <div className="w-4/6">
                            <Field
                              className="placeholder-gray-700 appearance-none block w-full text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none"
                              type="email"
                              placeholder="Email"
                              pattern="[\s\S]+@[\s\S]+\.[\s\S]+$"
                              name="email"
                            />
                          </div>
                          <div className="w-2/6 flex justify-end">
                            <button
                              type="submit"
                              className="border-l border-secondary flex items-center font-medium text-primary py-2 px-5 leading-tight rounded-r text-lg outline-none focus:shadow-outline"
                            >
                              Sign up
                            </button>
                          </div>
                        </div>
                        <ErrorMessage name="email">
                          {msg => (
                            <div className="mt-2 text-red-600">{msg}</div>
                          )}
                        </ErrorMessage>
                        {successMessage && (
                          <p className="text-gray mt-2">{successMessage}</p>
                        )}
                      </Form>
                    )}
                  </Formik>
                  <p className="text-gray text-sm mt-2">
                    You can unsubscribe at any time. Read our{' '}
                    <Link to="/privacy">privacy policy</Link>.
                  </p>
                </div>
              </footer>
            </div>
            {!frontmatter.disableTableOfContents && (
              <Toc headings={headings} docsRef={docsRef} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      excerpt
      timeToRead
      html
      headings {
        value
        depth
      }
      htmlAst
      frontmatter {
        description
        disableTableOfContents
        title
      }
    }
  }
`;
