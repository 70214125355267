import {Search as SearchFeather} from 'react-feather';
import React, {useState, useRef, useEffect} from 'react';
import './Search.css';

const KEY_SLASH = 191;

export const Search = () => {
  // eslint-disable-next-line no-unused-vars
  const [enabled, setEnabled] = useState(true);
  const inputRef = useRef(null);

  const handleDocKey = event => {
    if (event.keyCode === KEY_SLASH && inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (window.docsearch) {
      window.docsearch({
        apiKey: '0092bfa84bf99013da0628b038433471',
        indexName: 'fleetfn',
        inputSelector: '#algolia-doc-search',
        debug: false,
      });
    } else {
      setEnabled(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keyup', handleDocKey, true);

    return () => {
      document.removeEventListener('keyup', handleDocKey, true);
    };
  }, []);

  return (
    <div className="inline-flex w-2/4">
      <input
        className="form-control outline-none w-full py-2 pl-5 pr-5 border-r-0 border rounded-l focus:outline-none appearance-none border-secondary font-light"
        id="algolia-doc-search"
        placeholder="Search documentation..."
        ref={inputRef}
        type="text"
      />
      <div className="flex items-center rounded-r border-l-0 border border-secondary">
        <SearchFeather size={20} color="#DBDBDB" className="mr-4" />
      </div>
    </div>
  );
};
