import React, {useEffect, useState} from 'react';
import c8S from 'classnames';

const getAnchor = value =>
  value
    .toLowerCase()
    .split(' ')
    .join('-');

const memoize = functor => {
  let cache = {};
  return n => {
    if (n in cache) {
      return cache[n];
    } else {
      const result = functor(n);

      cache[n] = result;
      return result;
    }
  };
};

const memoizedHeadings = memoize(headings =>
  headings
    .map(item => {
      const anchor = getAnchor(item.value);
      const element = document.getElementById(anchor);

      return {
        ...item,
        offsetTop: element.offsetTop,
      };
    })
    .map((item, i, arr) => {
      const next = arr[parseInt(i) + 1]
        ? arr[parseInt(i) + 1].offsetTop
        : 100000;
      return {
        ...item,
        height: next - item.offsetTop,
      };
    })
);

const ToC = ({headings}) => {
  const [activeHeading, setActiveHeading] = useState(null);

  const handleScroll = () => {
    const currentScroll = window.scrollY;
    const headingsWithPosition = memoizedHeadings(headings);

    const currentHeading = headingsWithPosition.findIndex(item => {
      return (
        currentScroll >= item.offsetTop * 0.6 &&
        currentScroll <= item.offsetTop + item.height * 0.6
      );
    });

    if (currentHeading !== activeHeading) {
      setActiveHeading(currentHeading);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);

    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [headings]);

  return (
    <div className="nav-toc">
      <p className="text-xs text-gray mb-3 font-medium">ON THIS PAGE</p>
      <ul className="nav-items">
        {headings.map(({value}, i) => (
          <li key={i}>
            <a
              className={c8S('text-sm text-gray hover:text-primary', {
                'text-primary': i === activeHeading,
              })}
              href={'#' + getAnchor(value)}
            >
              {value}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ToC;
